<template>
  <div>
    <el-button
      type="primary"
      size="small"
      @click="showMap=true"
    >
      地图查找
    </el-button>
    <el-dialog
      title="选择经纬度"
      :visible.sync="showMap"
      width="800px"
      :before-close="handleClose"
      @opened="opened"
    >
      <div>
        <el-form
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item
            required
            label="地址名称："
          >
            <el-input
              v-model.trim="name"
              class="width360 margin_right_10"
              size="mini"
              show-word-limit
              placeholder="请输入位置"
              @change="changeName"
              @keyup.enter.native="placeSearch(name)"
            ></el-input>
            <el-button
              type="primary"
              size="mini"
              @click="placeSearch(name)"
            >
              搜索
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="getNow"
            >
              获取当前位置
            </el-button>
          </el-form-item>
          <el-form-item label="地址经纬度：">
            <el-input
              v-model="longitude"
              class="width140 margin_right_10"
              size="mini"
              show-word-limit
              placeholder="经度"
            ></el-input>
            <el-input
              v-model="latitude"
              class="width140 margin_right_10"
              size="mini"
              show-word-limit
              placeholder="纬度"
            ></el-input>
          </el-form-item>
        </el-form>
        <div id="bmap"></div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          @click="changeMap"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import AMap from "AMap";
export default {
  data() {
    return {
      showMap: false,
      mapObj: '',
      zoom: 12,
      name: '',
      longitude: 116.397451,
      latitude: 39.909187,
      poiArr: [], // 左边数据
      marker: [],
      windowsArr: [] // 信息窗口的数组
    }
  },
  mounted() {
    setTimeout(() => {}, 100)
  },
  methods: {
    opened() {
      console.log('打开')
      if (!this.mapObj) {
        this.createMap()
      }
    },
    changeMap() {
      this.$emit('getMapData', {
        name: this.name,
        lng: this.longitude,
        lat: this.latitude
      })
      this.showMap = false
    },
    handleClose() {
      this.showMap = false
    },
    createMap() {
      let _this = this
      this.mapObj = new AMap.Map('bmap', {
        zoom: this.zoom,
        zooms: [4, 18],
        center: [this.longitude, this.latitude],
        resizeEnable: true, // 缩放开关
        dragEnable: true, // 拖拽开关
        layers: [new AMap.TileLayer()],
        mapStyle: 'amap://styles/fresh'
      })

      // let marker = new AMap.Marker({
      //   position: [119.673679, 29.685675], //位置
      // });
      // this.mapObj.add(marker); //添加到地图

      let lineArr = []
      this.mapObj.on('complete', () => {
        console.log('地图加载完成')
        if (!this.name) {
          this.getNow()
        } else {
          this.placeSearch(this.name)
        }
      })
      this.mapObj.on('click', (e) => {
        // let lng = e.lnglat.getLng(); //获取经度
        // let lat = e.lnglat.getLat(); //获取纬度
        // marker点
        //   let marker = new AMap.Marker({
        //     position: [lng, lat],
        //     offset: new AMap.Pixel(-13, -30),
        //   });

        // this.longitude = lng;
        // this.latitude = lat;

        //   this.mapObj.add(marker); //加载点
        //   marker.on('click',(e,f)=>{
        //       console.log(e,f,777)
        //     //   this.addmarker(1,e)
        //   });//绑定click事件
      })
    },
    changeName() {
      this.longitude = ''
      this.latitude = ''
    },
    // 根据名字地址去搜索结果
    placeSearch(name) {
      this.mapObj.clearMap()
      if (!name) {
        return
      }
      var MSearch
      this.mapObj.plugin(
        ['AMap.PlaceSearch', 'AMap.ToolBar', 'AMap.Scale'],
        () => {
          this.mapObj.addControl(new AMap.ToolBar())
          this.mapObj.addControl(new AMap.Scale())
          MSearch = new AMap.PlaceSearch({
            // 构造地点查询类
            city: '' // 城市
          })
          AMap.event.addListener(
            MSearch,
            'complete',
            this.keywordSearch_CallBack
          ) // 返回地点查询结果
          MSearch.search(name) // 关键字查询
        }
      )
    },
    // 结果的回调
    keywordSearch_CallBack(data) {
      console.log(data, 999)
      var poiArr = data.poiList.pois
      var resultCount = poiArr.length
      this.poiArr = poiArr // 左边要渲染的数据
      for (var i = 0; i < resultCount; i++) {
        this.addmarker(i, poiArr[i])
        this.poiArr[i].url = this.poiArr[i].photos
          ? this.poiArr[i].photos[0]
            ? this.poiArr[i].photos[0].url
            : ''
          : ''
      }
      this.mapObj.setFitView()
    },
    // 添加marker&infowindow{}
    addmarker(i, d) {
      var lngX = d.location.getLng()
      var latY = d.location.getLat()
      var markerOption = {
        map: this.mapObj,
        position: new AMap.LngLat(lngX, latY)
      }
      var mar = new AMap.Marker(markerOption)
      this.marker.push(new AMap.LngLat(lngX, latY))
      var infoWindow = new AMap.InfoWindow({
        content:
          '<h3>' +
          '名称：' +
          d.name +
          '</h3>' +
          this.TipContents(d.name, d.address) +
          `</br>
        经度：${lngX}
        </br>
        纬度：${latY}
          `,
        size: new AMap.Size(300, 0),
        autoMove: true,
        offset: new AMap.Pixel(0, -30)
      })
      this.windowsArr.push(infoWindow)
      var _this = this
      var aa = (e) => {
        var obj = mar.getPosition() // 这里保存的地址经纬度

        window.removeEventListener('click', _this.demo)
        infoWindow.open(_this.mapObj, obj)
        window.addEventListener('click', _this.demo)

        _this.longitude = d.location.lng
        _this.latitude = d.location.lat
        console.log(d, 999)
        _this.name = d.name // 这里保存的是地址名字
        // console.log(obj, 999);
      }
      AMap.event.addListener(mar, 'click', aa)
    },
    TipContents(name, address) {
      // 窗体内容
      if (
        name == '' ||
        name == 'undefined' ||
        name == null ||
        name == ' undefined' ||
        typeof name === 'undefined'
      ) {
        type = '暂无'
      }
      if (
        address == '' ||
        address == 'undefined' ||
        address == null ||
        address == ' undefined' ||
        typeof address === 'undefined'
      ) {
        address = '暂无'
      }
      var str = `地址：${address}`
      return str
    },
    demo(e) {
      if (e.target.id === 'me') {
        e.stopPropagation()
      }
    },
    getNow() {
      let _this = this
      _this.mapObj.plugin('AMap.Geolocation', function() {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true

          timeout: 10000, // 超过10秒后停止定位，默认：无穷大

          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)

          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false

          buttonPosition: 'RB'
        })

        _this.mapObj.addControl(geolocation)

        geolocation.getCurrentPosition()

        AMap.event.addListener(geolocation, 'complete', onComplete) // 返回定位信息

        AMap.event.addListener(geolocation, 'error', onError) // 返回定位出错信息
      })
      // 解析定位结果

      function onComplete(data) {
        console.log(data)

        console.log(data.formattedAddress)

        console.log('纬度：' + data.position.getLat())

        console.log('经度：' + data.position.getLng())
        _this.name = data.formattedAddress
        _this.longitude = data.position.getLng()
        _this.latitude = data.position.getLat()
        _this.placeSearch(data.formattedAddress) // 搜索
      }

      // 解析定位错误信息

      function onError(data) {
        console.log('定位失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  padding: 0 20px;
}
#bmap {
  width: 100%;
  height: 500px;
  position: relative;
}
.el-form-item{
    margin-bottom: 0px;
}
</style>
